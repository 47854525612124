<template>
  <div id="app" class="window">
    <b-toast id="example-toast" title="Message" :variant="alert.type" auto-hide-delay="10000">
      {{alert.message}}
    </b-toast>
    <b-navbar toggleable="lg" sticky type="dark" class="nav-color">
      <b-navbar-brand href="/">
        <img src="@/assets/signin image.png" class="Gflbrand"/>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="/signin" v-if="!status.loggedIn" right>Sign in</b-nav-item>
          <b-nav-item v-if="status.loggedIn" right>Dernier rapport: {{logger.value}}</b-nav-item>
          <b-nav-item-dropdown v-if="status.loggedIn" text="Utilisateur" right>
            <!--<b-dropdown-item href="/setting">Setting</b-dropdown-item>-->
            <b-dropdown-item href="/" @click="clickLogout">Logout</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-container fluid>
      <router-view/>
    </b-container>
  </div>
</template>

<script>
  import {mapState,mapActions} from 'vuex'

  export default {
      name: 'app',
      components: {

      },
      data() {
        return {
          showToast : false,
          shoppingCog:['fas', 'ruler'],
          favoriteIcon:['fas', 'heart'],
          shoppingCart:['fas', 'shopping-cart'],
          logoutIcon:['fas', 'sign-out-alt'],
          shoppingBag:['fas', 'shopping-bag']
        }
      },
      computed: {
        ...mapState({
          user: state => state.authentication.user,
          status: state => state.authentication.status,
          alert: state => state.alert,
          alertToggle: state => state.alert.toggle,
          logger: state => state.data.logger
        }),
      },
      methods:{
        ...mapActions([
          'logout',
          'getLogger'
        ]),
        clickLogout(){
          this.logout()
          this.$router.push('/')
        },
        loggerInfo(){
          if(this.status.loggedIn){
            this.getLogger()
          }
        }
      },
      watch:{
          $route (){
              // clear alert on location change
              this.$store.dispatch('alert/clear');
          },
          alertToggle(){
            this.$bvToast.show('example-toast')
          }
      },
      mounted() {
        //do something after mounting vue instance
        this.loggerInfo()
        setInterval(function(){
          this.loggerInfo()
        }.bind(this),15 * 60 * 1000);
      },
      created() {
      }
  };
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    width: 100%;
    min-height: 100vh;
    background-color: #FFF;
    color: #5c5c5c;
  }
  #nav {
    padding: 30px;
    a {
      font-weight: bold;
      color: #2c3e50;
      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
  .box{
    width: 100%;
    position: absolute;
  }

  .logo {
    cursor: pointer;
  }

  .background {
    background-color: #8750a4 !important;
  }

  .nav-color {
    background-color: #5c5c5c !important;
  }

  .Gflbrand {
    height: 40px;
  }

  .title{
    margin-top: 50px;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
  }

  .title2{
    text-align: center;
    font-size: 30px;
    font-weight: bold;
  }

  .left{
    text-align: center;
  }

  .first{
    margin-top: 40px;
  }

  .end{
    margin-bottom: 100px;
  }

  .second{
    margin-top: 50px;
  }

  .startButton{
    background-color: #94d60a !important;
    font-size: 30px !important;
    width: 100% !important;
    height: 100px !important;
  }

  .warningButton{
    background-color: #ffdc17 !important;
    font-size: 30px !important;
    width: 100% !important;
    height: 100px !important;
  }

  .stopButton{
    background-color: #FF4C33 !important;
    font-size: 30px !important;
    width: 100% !important;
    height: 100px !important;
  }

</style>
