import Api from '@/services/api'

const basic = 'data';
export default {
    getData (params) {
        let query = '?'
        if(params.type){
          query += 'type='+params.type+'&'
        }
        if(params.loggerId){
            query += 'loggerId='+params.loggerId+'&'
        }
        if(params.minDate){
            query += 'minDate='+params.minDate+'&'
        }
        if(params.maxDate){
            query += 'maxDate='+params.maxDate+'&'
        }
        if(params.stripe){
            query += 'stripe='+params.stripe+'&'
        }
        return Api.default().get('/'+basic+'/'+query)
    },
    getLastData (params) {
        let query = '?'
        if(params.type){
          query += 'type='+params.type+'&'
        }
        if(params.loggerId){
            query += 'loggerId='+params.loggerId+'&'
        }
        return Api.default().get('/'+basic+'/last/'+query)
    },
    getPump (params) {
        let query = '?'
        if(params.pumpId){
          query += 'pumpId='+params.pumpId+'&'
        }
        if(params.raw !== undefined){
            query += 'raw='+params.raw+'&'
        }
        if(params.minDate){
            query += 'minDate='+params.minDate+'&'
        }
        if(params.maxDate){
            query += 'maxDate='+params.maxDate+'&'
        }
        return Api.default().get('/'+basic+'/pump/'+query)
    },
    getLastPump (params) {
        let query = '?'
        if(params.pumpId){
          query += 'pumpId='+params.pumpId+'&'
        }
        return Api.default().get('/'+basic+'/pump/last/'+query)
    },
    postPump(params){
      //console.log(params)
      return Api.default().post('/'+basic+'/pump/',params)
    },
    getLogger(){
      return Api.default().get('/'+basic+'/logger/')
    },
    putLogger(params){
      return Api.default().put('/'+basic+'/logger/',params)
    },
    postRecalStat(params){
      return Api.default().post('/'+basic+'/recalstat/',params)
    },
    postData(params){
      return Api.default().post('/'+basic+'/',params)
    }

}
