<template>
  <div>
    <img src="@/assets/signin image.png" class="Gfllogo"/>
    <b-form @submit.prevent="onSubmit" class="form" >
      <h1 v-if="type === 'signup'">Sign up</h1>
      <h1 v-if="type === 'validate'">Continue registration</h1>
      <h1 v-if="type === 'signin'">Site de Brossard</h1>
      <b-form-group
        id="signin-group-1"
        label="Email:"
        label-for="signin-1"
      >
        <b-form-input
          id="signin-1"
          v-model="form.email"
          :type="emailType"
          autocomplete="off"
          :readonly="isForm"
          required
          placeholder="Entrer votre email"
        ></b-form-input>
      </b-form-group>

      <div v-if="type === 'validate'">
        <b-form-group
          id="signin-group-2"
          label="Nom:"
          label-for="signin-2"
        >
          <b-form-input
            id="signin-2"
            v-model="form.name"
            type="text"
            placeholder="Entrer votre nom"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="signin-group-3"
          label="Nom de famille:"
          label-for="signin-3"
        >
          <b-form-input
            id="signin-3"
            v-model="form.lastName"
            type="text"
            placeholder="Entrez votre nom de famille"
          ></b-form-input>
        </b-form-group>
      </div>
      <b-form-group v-if="type !== 'signup'" id="signin-group-4" label="Votre mot de passe:" label-for="signin-4">
        <b-form-input
          id="signin-4"
          type="password"
          v-model="form.password"
          autocomplete="off"
          required
          placeholder="Entrer votre mot de passe"
        ></b-form-input>
      </b-form-group>
      <b-button type="submit" variant="dark" v-if="type === 'signup'">Send</b-button>
      <b-button type="submit" variant="dark" v-if="type === 'validate'">Send</b-button>
      <b-button type="submit" variant="dark" v-if="type === 'signin'">Enter</b-button>
      <!--<div v-if="type !== 'validate'">
        <Seperator/>
        <div class="separator">OR</div>
        <Seperator/>
        <b-row>
          <b-col lg="2">
          </b-col >
          <b-col lg="4">
            <GoogleSigninButton></GoogleSigninButton>
          </b-col >
          <b-col lg="4">
            <FacebookSigninButton></FacebookSigninButton>
          </b-col>
          <b-col lg="2">
          </b-col >
        </b-row>
      </div>-->
      <!--<p v-if="type !== 'signin'"><a class="nav-link" href="/">Return to sign in</a></p>
      <p v-if="type === 'signin'"><a class="nav-link" href="/signup">You are not register?</a></p>-->
    </b-form>
  </div>
</template>
<script>
  import {mapActions} from 'vuex';

  //import GoogleSigninButton from 'components/GoogleSigninButton';
  //import FacebookSigninButton from 'components/FacebookSigninButton';
  //import Seperator from '@/components/Seperator.vue';

  export default {
    components: {
      //GoogleSigninButton,
      //FacebookSigninButton,
      //Seperator
    },
    props: {
      type: String,
      user: Object
    },
    data() {
      return {
        form: {},
        isForm:false,
        emailType:'email',
        options: [
          { value: 'fr', text: 'Français' },
          { value: 'en', text: 'English' }
        ]
      };
    },
    computed:{},
    watch:{
    },
    methods:{
      ...mapActions([
        'login',
        'register',
        'validation',
        'setLangValue',
      ]),
      onSubmit() {
        if(this.type === 'signin'){
          this.login(this.form)
        }
        if(this.type === 'signup'){
          this.register(this.form)
        }
        if(this.type === 'validate'){
          this.validation(this.form)
        }
      },
    },
    mounted() {
      if(this.type === 'validate'){
        this.form = JSON.parse(JSON.stringify(this.user))
        this.isForm = true
        this.emailType = 'text'
      }

    },
    created(){

    }

  };

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .form {
    width: 70%;
    margin-top: 2%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .Gfllogo {
    height: 200px;
    margin-top: 6%;
  }

  .langSelect {
    margin-top: 2%;
  }

  .separator {
    display: flex;
    align-items: center;
    text-align: center;
  }
  .separator::before, .separator::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid #000;
  }
  .separator::before {
      margin-right: .25em;
  }
  .separator::after {
      margin-left: .25em;
  }
</style>
