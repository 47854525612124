import Vue from 'vue'
import Router from 'vue-router'
//import store from '../store/'

import Home from '../views/Home.vue'
import Signin from '../views/Signin.vue'

//import { i18n } from './i18n'

Vue.use(Router)

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/signin',
      name: 'signin',
      component: Signin
    }
  ]
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  let baseUrl = '/'+to.path.toLowerCase().split('/')[1];
  baseUrl = baseUrl.split('?')[0];
  //console.log(baseUrl)
  const publicPages = ['/signin'];
  const authRequired = !publicPages.includes(baseUrl);
  //console.log(authRequired)
  const loggedIn = localStorage.getItem('user');
  //console.log(loggedIn)
  if (authRequired && !loggedIn) {
    return next('/signin');
  }

  next();
})
