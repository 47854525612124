import Service from '@/services/data';

/*const user = JSON.parse(localStorage.getItem('user'));
const initialUser = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: {token:''} };*/

export default {
  namespaced: false,
  state: {
    values:{},
    lastValues:{},
    pumpValues:[],
    totalPumpDischarged:0,
    lastPumpValue:{},
    logger:{},
    postDataOver: false
  },
  actions: {
    getData({ dispatch, commit}, payload) {
      Service.getData(payload.param).then( (body) => {
        body.valueType = payload.valueType
        commit('setValues', body);
      }).catch((err)=>{
        dispatch('alert/error', err);
      })
    },
    getLastData({ dispatch, commit}, payload) {
      Service.getLastData(payload.param).then( (body) => {
        body.valueType = payload.valueType
        commit('setLastValues', body);
      }).catch((err)=>{
        dispatch('alert/error', err);
      })
    },
    getPumpValues({ dispatch, commit}, payload) {
      Service.getPump(payload.param).then( (body) => {
        commit('setPumpValues', body.data);
      }).catch((err)=>{
        dispatch('alert/error', err);
      })
    },
    getLastPump({ dispatch, commit}, payload) {
      Service.getLastPump(payload.param).then( (body) => {
        commit('setLastPumpValue', body.data);
      }).catch((err)=>{
        dispatch('alert/error', err);
      })
    },
    postPump({ dispatch, commit}, payload) {
      Service.postPump(payload.param).then( (body) => {
        commit('setLastPumpValue', body.data);
      }).catch((err)=>{
        dispatch('alert/error', err);
      })
    },
    getLogger({ dispatch, commit}){
      Service.getLogger().then( (body) => {
        commit('setLogger', body.data);
      }).catch((err)=>{
        dispatch('alert/error', err);
      })
    },
    putLogger({ dispatch}, payload) {
      Service.putLogger(payload).then( (body) => {
        dispatch('getLogger');
        dispatch('alert/success', 'Setting saved');
      }).catch((err)=>{
        dispatch('alert/error', err);
      })
    },
    postRecalStat({ dispatch}, payload) {
      Service.postRecalStat(payload).then( (body) => {
        dispatch('alert/success', 'Recalculated done');
      }).catch((err)=>{
        dispatch('alert/error', err);
      })
    },
    postData({ dispatch, commit}, payload) {
      Service.postData(payload).then( (body) => {
        commit('postDataOver');
        dispatch('alert/success', 'Parsing done');
      }).catch((err)=>{
        dispatch('alert/error', err);
      })
    },

  },
  mutations: {
    setValues(state, body) {
        state.values = JSON.parse(JSON.stringify(state.values))
        state.values[body.valueType] = body.data
    },
    setLastValues(state, body) {
        state.lastValues = JSON.parse(JSON.stringify(state.lastValues))
        state.lastValues[body.valueType] = body.data
    },
    setPumpValues(state, values) {
        state.pumpValues = values.data
        state.totalPumpDischarged = values.total
    },
    setLastPumpValue(state, value) {
        if(!value.status){
          value.status = 'stopped'
        }

        state.lastPumpValue = value
    },
    setLogger(state, value) {
        state.logger = JSON.parse(JSON.stringify(value))
    },
    postDataOver(state) {
        state.postDataOver = !state.postDataOver
    },

  }
}
