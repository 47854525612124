import axios from 'axios'
import store from '../store/'
import {router} from '@/plugins/router';

const defaults = {
  statusCodes: [
      401 // Unauthorized
  ]
};

let backUrl = 'https://gflbrossardapi.jsguil.com/'

if(process.env.NODE_ENV === 'development'){
  backUrl = 'http://localhost:8787/';
}

console.log(backUrl)

console.log(backUrl)

const refreshAuthLogic = (failedRequest) => store.dispatch('refreshToken',{token:store.state.authentication.user.token}).then((response) => {
   failedRequest.response.config.headers['Authorization'] = response.data.token;
   return Promise.resolve();
 }).catch(() => {
   store.dispatch('logout').then(() => {
     router.push('/signin')
   }).catch(() => {
     router.push('/signin')
   })
 });

export default {
  default () {

    const instance = axios.create({
        baseURL: backUrl,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })

    instance.interceptors.request.use(
      (config) => {
        const token = store.state.authentication.user.token
        if (token) {
          config.headers['Authorization'] = token
        }
        //console.log(config)
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    // Response
    createAuthRefreshInterceptor (instance,refreshAuthLogic)

    return instance
  }

}

function createAuthRefreshInterceptor (axios, refreshTokenCall, options = {}) {
    const id = axios.interceptors.response.use(res => res, error => {
        // Reject promise if the error status is not in options.ports or defaults.ports
        const statusCodes = options.statusCodes.length //prototype-builtins
            ? options.statusCodes
            : defaults.statusCodes;
        console.log(statusCodes.indexOf(+error.response.status) === -1)
        console.log(error.response.data.message !== 'Expired token')
        console.log(error.response.status && statusCodes.indexOf(+error.response.status) === -1 && error.response.data && error.response.data.message !== 'Expired token')
        if ((!error.response && !error.response.data ) || (error.response.status && statusCodes.indexOf(+error.response.status) === -1) || (error.response.data.message !== 'Expired token')) {
            return Promise.reject(error);
        }

        // Remove the interceptor to prevent a loop
        // in case token refresh also causes the 401
        axios.interceptors.response.eject(id);

        const refreshCall = refreshTokenCall(error);

        // Create interceptor that will bind all the others requests
        // until refreshTokenCall is resolved
        const requestQueueInterceptorId = axios.interceptors
            .request
            .use(request => refreshCall.then(() => request));

        // When response code is 401 (Unauthorized), try to refresh the token.
        return refreshCall.then(() => {
            axios.interceptors.request.eject(requestQueueInterceptorId);
            return axios(error.response.config);
        }).catch(error => {
            axios.interceptors.request.eject(requestQueueInterceptorId);
            return Promise.reject(error)
        }).finally(() => createAuthRefreshInterceptor(axios, refreshTokenCall, options));
    });
    return axios;
}
