import { render, staticRenderFns } from "./Seperator.vue?vue&type=template&id=77b28ea6&"
var script = {}
import style0 from "./Seperator.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports