<template>
  <div>
    <b-container fluid>
      <p class="title">Site de Brossard</p>
      <hr class="nav-color">
      <b-row>
        <b-col md="3">
          <Value v-if="lastValues.lastLoggerData" class="second"
            title="Débit"
            :value="lastValues.lastLoggerData.debit"
            unit="gpm"
            :date="lastValues.lastLoggerData.date"
          />
        </b-col>
        <b-col md="3">
          <Value v-if="lastValues.lastLoggerData" class="second"
            title="Galonnage de la période"
            :value="this.galonnage"
            unit="gallons"
          />
        </b-col>
        <b-col md="3">
          <Value v-if="lastValues.lastLoggerData" class="second"
            title="Niveau du puit"
            :value="lastValues.lastLoggerData.hauteurNivPuit/100"
            unit="m"
            :date="lastValues.lastLoggerData.date"
          />
        </b-col>
        <b-col md="3">
          <Value v-if="lastValues.lastLoggerData" class="second"
            title="Vitesse"
            :value="lastValues.lastLoggerData.vitesse"
            unit="RPM"
            :date="lastValues.lastLoggerData.date"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col offset-xl="2" xl="8">
          <p class="title2 second">Dernière valeur</p>
        </b-col>
      </b-row>
      <b-row >
        <b-col offset-xl="2" xl="3">
          <b-form-group label-cols="4" label-cols-lg="2" label="Min:">
            <b-form-input
              v-model="minDate"
              type="date"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col xl="3">
          <b-form-group label-cols="4" label-cols-lg="2" label="Max:">
            <b-form-input
              v-model="maxDate"
              type="date"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col xl="2">
          <b-form-group label-cols="4" label-cols-lg="2">
            <b-button @click="getHistory">Apply</b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12">
          <b-form-group>
            <b-button @click="downloadCSV">Export CSV</b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset-xl="2" xl="8">
          <VueApexCharts class="" :height="500" type="area" :options="chartOptions" :series="series"></VueApexCharts>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Seperator from '@/components/Seperator.vue';
  import Value from '@/components/Value.vue';
  import VueApexCharts from 'vue-apexcharts'

  export default {
    name: 'home',
    components: {
      //Seperator,
      VueApexCharts,
      Value
    },
    data() {
      return {
        minFlow: 60,
        selected: 12,
        galonnage: 0,
        minDate:undefined,
        minFormatedDate:undefined,
        maxDate:undefined,
        maxFormatedDate:undefined,
        series: [
          {
            name: 'Débit',
            type:'column',
            data: []
          },
          {
            name: 'Galonnage journalier',
            data: []
          },
          {
            name: 'Niveau du puit',
            data: []
          },
          {
            name: 'Vitesse',
            data: []
          },
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: 'area',
            zoom: {
              autoScaleYaxis: true
            },
            animations: {
              enabled: false,
            },
            toolbar: {
             show: true,
             offsetX: 0,
             offsetY: 0,
             tools: {
               download: false,
               selection: true,
               zoom: true,
               zoomin: true,
               zoomout: true,
               pan: true,
               reset: true | '<img src="/static/icons/reset.png" width="20">',
               customIcons: []
             },
             autoSelected: 'zoom'
           },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [5, 3, 5, 5]
          },
          xaxis: {
             type: 'datetime',
             tickAmount: 1,
          },
          yaxis: [
            {
              seriesName: 'Débit',
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#008FFB'
              },
              labels: {
                style: {
                  colors: '#008FFB',
                }
              },
              title: {
                text: "Débit (gpm)",
                style: {
                  color: '#008FFB',
                }
              },
              tooltip: {
                enabled: true
              },
            },
            {
              seriesName: 'Galonnage journalier',
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#00E396'
              },
              labels: {
                style: {
                  colors: '#00E396',
                }
              },
              title: {
                text: "Galonnage journalier (gallons)",
                style: {
                  color: '#00E396',
                }
              },
              tooltip: {
                enabled: true
              },
            },
            {
              seriesName: 'Niveau du puit',
              opposite: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#FEB019'
              },
              labels: {
                style: {
                  colors: '#FEB019',
                },
              },
              min: 0,
              title: {
                text: "Niveau du puit (m)",
                style: {
                  color: '#FEB019',
                }
              },
            },
            {
              seriesName: 'Vitesse',
              opposite: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#FF0000'
              },
              labels: {
                style: {
                  colors: '#FF0000',
                },
              },
              title: {
                text: "Vitesse (RPM)",
                style: {
                  color: '#FF0000',
                }
              },
              //min:99,
              //max:103
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            },
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40
          }
        },

      }
    },
    computed:{
      ...mapState({
        status: state => state.authentication.status,
        values: state => state.data.values,
        lastValues: state => state.data.lastValues,
        lastPumpValue: state => state.data.lastPumpValue,
        pumpValues: state => state.data.pumpValues,
        totalPumpDischarged: state => state.data.totalPumpDischarged
      })
    },
    watch:{
      lastValues(){
        this.setLastValue()
      },
      values(){
        this.createChart()
      },
      pumpValues(){
        this.createPumpChart()
      },
      minDate(){
        let date = new Date(this.minDate)
        this.minFormatedDate = date.getTime()+date.getTimezoneOffset() * 60 * 1000
        this.minFormatedDate = new Date(this.minFormatedDate)
      },
      maxDate(){
        let date = new Date(this.maxDate)
        this.maxFormatedDate = date.getTime()+date.getTimezoneOffset() * 60 * 1000
        this.maxFormatedDate = new Date(this.maxFormatedDate)
      }
    },
    methods:{
      ...mapActions([
        'getLastData',
        'getLastPump',
        'getPumpValues',
        'postPump',
        'getData',
        'getLogger'
      ]),
      downloadCSV(){
        //this.convertToCSV('Date,Débit (gpm),Galonnage journalier (gallons),Niveau du puit (m),Vitesse (RPM)',this.values.csvfile)
        this.exportCSVFile('Date,Débit (gpm),Galonnage journalier (gallons),Niveau du puit (m),Vitesse (RPM)', this.values.csvfile, 'data_'+this.minDate+'_'+this.maxDate)
      },
      convertToCSV(header,objArray) {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

        let str = 'Galonnage de la période (gallons)\r\n'+this.galonnage+'\r\n';
        if(header){
          str += header + '\r\n';
        }
        for (let i = 0; i < array.length; i++) { // eslint-disable-line
          let line = '';
          for (const index in array[i]) { // eslint-disable-line
            if (line !== '') line += ',';
            line += array[i][index];
          }
          str += line + '\r\n'; // eslint-disable-line
        }
        //console.log(str)
        return str;
      },
      exportCSVFile(header,jsonObject, fileTitle) {
        const csv = this.convertToCSV(header,jsonObject);
        const exportedFilenmae = fileTitle + '.csv' || 'export.csv'; // eslint-disable-line
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          const link = document.createElement('a');
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      },
      getlastValues(){
        this.getLastData({valueType:'lastLoggerData',param:{loggerId:'logger1',type:'rt'}})
      },
      setLastValue(){
        if(this.lastValues.lastAvgFlowData && this.lastValues.lastAvgFlowData.flow){
          this.lastAvgFlowData = this.lastValues.lastAvgFlowData.flow
          //console.log('here1')
        }
        //console.log(this.lastAvgFlowData)
      },
      createChart(){
        if(this.values.rtData){
          this.series[0].data = []
          this.series[1].data = []
          this.series[2].data = []
          this.series[3].data = []
          for(let i = 0; i < this.values.rtData.length; i++){
            let date = new Date(this.values.rtData[i].date)
            date = date.getTime()-date.getTimezoneOffset() * 60 * 1000

            this.series[0].data.push([date, this.values.rtData[i].debit])
            this.series[1].data.push([date, this.values.rtData[i].galonnageJournalier])
            if(this.values.rtData[i].hauteurNivPuit/100 > 20){
              this.series[2].data.push([date, -0.01])
            }
            else{
              this.series[2].data.push([date, this.values.rtData[i].hauteurNivPuit/100])
            }
            this.series[3].data.push([date, this.values.rtData[i].vitesse])
          }
          this.series = JSON.parse(JSON.stringify(this.series))
        }

        if(this.values.csvfile){
          let valuesGal = []

          for(let i = 0; i < this.values.csvfile.length; i++){
            valuesGal.push(this.values.csvfile[i].galonnageJournalier)
          }

          this.intervalGalonnage(valuesGal)
        }

      },
      intervalGalonnage(value){
        this.galonnage = 0
        let firstValue = 0
        let maxValue = [0]
        let j=0
        let oldValue = 0
        for(let i in value){
          if(parseInt(i) === 0){
            firstValue = value[i]
          }

          if(oldValue > value[i]){
            maxValue[j] = oldValue
            maxValue.push(0)
            j++
          }
          if(parseInt(i) === (value.length - 1) ){
            maxValue[j] = oldValue
          }
          oldValue = value[i]
        }

        this.galonnage -= firstValue
        for(let i in maxValue){
          this.galonnage += maxValue[i]
        }
      },
      getHistory(){
        this.getData({valueType:'rtData',param:{loggerId:'logger1',type:'rt',minDate:this.minFormatedDate,maxDate:this.maxFormatedDate,stripe:10}})
        this.getData({valueType:'csvfile',param:{loggerId:'logger1',type:'rt',minDate:this.minFormatedDate,maxDate:this.maxFormatedDate}})
      }
    },
    mounted() {
      this.getlastValues()
      this.getHistory()
      this.getLogger()
      setInterval(function(){
        this.getlastValues()
      }.bind(this),15 * 60 * 1000);
    },
    created() {
    },
    destroyed() {
    },

  };

</script>
